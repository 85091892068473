.heading3 {
	position: relative;
}

.heading3::before {
	content: attr(data-number);
	display: block;
	margin-bottom: 30px;
	color: #fc5185;
	font-size: 30px;
}

.heading3::after {
	content: '';
	position: absolute;
	top: 42px;
	left: 50%;
	transform: translateX(-50%);
	width: 1px;
	height: 20px;
	background-color: #fc5185;
}

.heading4{
  position: absolute;
  top: -.75em;
  left: 1em;
  padding: 0 .5em;
  background-color: #fff;
  color: #2589d0;
  font-weight: 600;
  font-size: 1.1rem;
}

.list {
  position: relative;
  padding: 1.5em 1em 1em 2.5em;
  border: 2px solid #2589d0;
}

.list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list li {
  padding: .3em .3em .3em 0;
}

.main{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
